<template>
  <div class="mobile">
    <div class="fixed-header animate__animated animate__fadeIn">
  			<div class="fox-header">
  				<div class="top">
  					<div class="logos" @click="navHome">
  						<img class="logo" :src="require('@/assets/images/mobile/logo.png')" alt="">
  					</div>
  					<div class="right"  @click="handleMenu">
              <div class="btn" :class="showMenu?'cur':''">
                <div class="line line0"></div>
                <div class="line line1"></div>
                <div class="line line2"></div>
              </div>
  						<!-- <img class="icon-item" :src="require('@/assets/images/mobile/icon-ul.png')" @click="handleMenu" alt=""> -->
  					</div>
  				</div>
  				<div class="bottom" @touchmove.stop v-if="showMenu">
  					<div class="fox-ul" v-for="(item, index) in list" :key="index">
  						<div class="fox-li level_one animate__animated animate__fadeIn" @click="handleClick(item, index)">
  							<div>{{ item.title }}</div>
  							<!-- <div v-if="item.children.length > 0">
  								<i class="icon el-icon-plus"></i>
  							</div> -->
  						</div>
  						<div v-show="active_index == item.path" class="animate__animated animate__fadeIn">
  							<div class="fox-li level_two"  v-for="(items, i) in item.children" :key="i" @click="handleClick(items, i)">
  								<div>{{ items.title }}</div>
  								<!-- <div v-if="items.children">
  									<i class="icon el-icon-plus"></i>
  								</div> -->
  							</div>
  						</div>
  					</div>
  				</div>
  			</div>
  		</div>
    <transition name="fade-transform" mode="out-in">
      <router-view  />
    </transition>
    <phone-footer></phone-footer>
  </div>
</template>

<script>
import phoneFooter from '@/components/phoneFooter/index'
export default {
  components: { phoneFooter },
  data () {
    return {
      active_index: "",
      showMenu: false,
      list: [
        {
          title: "首页",
          level: 1,
          path: "phoneIndex",
          children: []
        },
        {
          title: "关于我们",
          level: 1,
          path: "paboutUs",
          children: []
        },
        {
          title: "服务范畴",
          level: 1,
          path: "pserviceArea",
          children: []
        },
        {
          title: "帮助中心",
          level: 1,
          path: "phelpCenter",
          children: []
        },
        {
          title: "语言选择",
          level: 1,
          path: "/phoneContact",
          children: [
            { title: "简体", level: 2, key:'zh', path: "zh" },
            { title: "繁体", level: 2, key: 'cht',  path: "cht" },
            { title: "English", level: 2, key:'en', path: "en" },
          ]
        }
      ]
      }
  },
  methods: {
      navHome () {
        this.$router.push("/phoneIndex").then(() => {
          this.active_index = "";
          this.showMenu = false;
        });
      },
      handleClick (item, index) {
        console.log(item, index);
        if (item.level === 1) {
          if (!item.children || item.children.length == 0) {
          this.$router.push({ name: item.path }).then(() => {
            this.active_index = "";
            this.showMenu = false;
          });
        } else {
          if (this.active_index == item.path && item.level == 1) {
            this.active_index = "";
          } else {
            this.active_index = item.path;
          }
        }
        }
        if (item.level == 2) {
          this.$i18n.locale = item.key
          window.localStorage.setItem('lang', item.key)
          this.showMenu = false;
          this.$router.go(0)
        }

      },

      handleMenu () {
        this.showMenu = !this.showMenu;
      }
    }
  }
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/var.scss';
.mobile{
  padding-top: toPx(116);
  box-sizing: border-box;
}

.fixed-header {
  width: toPx(750);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  .fox-header {
    position: relative;
    width: 100%;
    height: toPx(116);
    background-color: #ffffff;
    box-sizing: border-box;
    z-index: 999;
    .top {
      position: relative;
      z-index: 10;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      box-shadow: 0 toPx(2) toPx(9) 0 rgba(93,113,136,0.09);
      .logos{
        width: toPx(250);
        height: toPx(116);
        text-align: center;
        padding: toPx(33) 0;
        background-color: $--color-main;
      }
      .logo {
        width: toPx(190);
        height: toPx(60);
        vertical-align: top;
      }
      & > .right{
        display:inline-flex;
        align-items: center;
        padding-right: toPx(30);
        .cur{
          width: toPx(36) !important;
          height: toPx(29) !important;
          overflow: hidden;
          .line0{
            transform: rotate(46deg);
          }
          .line1{
            display: none;
          }
          .line2{
            width: 100%;
            transform: rotate(-46deg);
          }
        }
        .btn{
          position: relative;
          display: inline-block;
          width: toPx(40);
          height: toPx(30);
          transition-duration: 0.3s;
        }
        .line{
          height: toPx(2);
          transition-duration: .3s;
          transform-origin: left center 0;
        }
        .line0{
          position: absolute;
          left: toPx(0);
          top: toPx(1);
          width: 100%;
          background-color: $--color-black;
        }
        .line1{
          position: absolute;
          left: toPx(0);
          top: toPx(13.5);
          width: 100%;
          background-color: $--color-black;
        }
        .line2{
          position: absolute;
          left: toPx(0);
          bottom: toPx(1);
          width: 100%;
          background-color: $--color-black;
        }
      }
      .icon-item {
        width: toPx(38);
        height: toPx(27);
        vertical-align: middle;
      }
    }
    & > .bottom {
      width: 100%;
      height: calc(100vh - toPx(116));
      background-color: var(--color-white);
    }
    .fox-ul {
      width: 100%;
      background-color: $--color-white;
      opacity: 1;
      .level_one {
        background-color: $--color-white;
        font-size: toPx(34);
        color: $--color-black;
      }
      .level_two {
        background-color: $--color-white;
        font-size: toPx(30);
        padding: toPx(20) 0 !important;
        color: $--color-gray-half !important;
      }
      .icon {
        width: toPx(40);
        height: toPx(40);
        line-height: toPx(40);
        text-align: center;
      }
      .fox-li {
        width: 100%;
        padding: toPx(40) 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
      }
    }
  }
}

.fadein-enter,
.fadein-leave-to {
  opacity: 0;
}
.fadein-enter-active,
.fadein-leave-active {
  transition: all 0.2s;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;
}
.fade-enter {
  opacity: 0;
  position: absolute;
  top: 0;
  transform: translateX(100%);
}
.fade-leave-to {
  transform: translateX(-100%);
  opacity: 0;
  position: absolute;
  top: 0;
}
</style>