import axios from "axios";
import { Message } from "element-ui";
import Store from "@/store";
import qs from "qs";
import Router from "@/router";
import { getToken } from "@/utils/auth";
const _axios = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
});

let flag = true;
// 请求拦截
_axios.interceptors.request.use(
  (config) => {
    // 暂时
    // console.log(getToken(), 77778887)
    config.headers.token = getToken();
    if (Store.state.user.token) {
      // config.headers.Authorization = `${Store.state.user.token}`
    }
    if (config.method === "post" && config.josn) {
      if (config.upload) {
        config.headers["Content-Type"] = "multipart/form-data";
      } else {
        config.headers["Content-Type"] = "application/x-www-form-urlencoded";
        config.data = qs.stringify(config.data);
      }
    }
    // console.log(config, 99999)
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
_axios.interceptors.response.use(
  (res) => {
    if (res.config.upload) {
      if (res.status === 200) {
        return Promise.resolve(res.data);
      } else {
        Message.error(res.statusText);
        return Promise.reject(res);
      }
    }
    if (res.data.status === 0) {
      return res.data;
    } else if (res.data.status === 401) {
      if (flag) {
        Message.error(res.data.message);
        flag = false;
      }
      setTimeout(() => {
        flag = true;
      }, 3000);
      // Store.dispatch('user/logout')
      Router.push("/login");
      return Promise.reject(res.data.message);
    } else {
      Message.error(res.data.message);
      return Promise.reject(res.data.message);
    }
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      Store.dispatch("user/logout");
      Message.error(error.response.data.message);
      Router.push("/login?redirect=" + window.location.href.split("#")[1]);
    }
    return Promise.reject(error);
  }
);
export default _axios;
