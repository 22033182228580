import Vue from 'vue'
import './plugins/request'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from "@/i18n";

import '@/icons' // icon

import 'animate.css';

import './styles.scss'

router.beforeEach((to, from, next) => {
	document.body.scrollTop = 0
	document.documentElement.scrollTop = 0
  // window.pageYOffset = 0
  window.scrollTo({
      top: 0,
      behavior: "smooth"
  });
	next()
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
