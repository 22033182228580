import Vue from "vue";
import VueI18n from "vue-i18n";
import ElementUI from "element-ui";

import elementEn from "element-ui/lib/locale/lang/en";
import elementZh from "element-ui/lib/locale/lang/zh-CN";
import elementTw from "element-ui/lib/locale/lang/zh-TW";
import cht from './locale/cht.json';
import zh from "./locale/zh.json";
import en from "./locale/en.json";



Vue.use(VueI18n);
Vue.use(ElementUI);

const i18n = new VueI18n({
  locale: window.localStorage.getItem("lang") || "cht",
  messages: {
    cht: {
      ...cht,
      ...elementTw
    },
    zh: {
      ...zh,
      ...elementZh
    },
    en: {
      ...en,
      ...elementEn
    }
  },
});

export default i18n;
