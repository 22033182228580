<template>
  <div class="footer" :class="['phoneIndex','paboutUs','pservice'].includes($route.name)?'bg-white':'bg-gray'">
    <div class="action">
      <div class="title">{{ $t('components.WebFooter.709891-0') }}</div>
      <div class="text"><span class="item" @click="nav('pserviceArea')">{{ $t('components.WebFooter.709891-1') }}</span><span class="item" @click="nav('phelpCenter')">{{ $t('components.WebFooter.709891-2') }}</span><span class="item" @click="nav('pappRule')">{{ $t('components.WebFooter.709891-3') }}</span></div>
      <div class="title">{{ $t('components.WebFooter.709891-4') }}</div>
      <div class="text">{{ $t('components.WebFooter.709891-5') }}</div>
      <div class="text">{{ $t('components.WebFooter.709891-6') }}{{ $t('components.WebFooter.709891-7') }}</div>
    </div>
    <div class="copyRight">{{ $t('components.WebFooter.709891-8') }}</div>
    <div class="safe-area"></div>
  </div>
</template>

<script>
export default {
    data () {
      return {

      }
  },
  methods: {
    nav (name) {
        this.$router.push({ name })
      }
    }
  }
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/var.scss';
.bg-white{
  background-color: var(--color-white);
}
.bg-gray{
  background-color: rgba(246, 248, 251, 1);
}
.footer{
  width: 100%;
  box-sizing: border-box;
  .action{
    padding: 0 toPx(60) toPx(60);
    box-sizing: border-box;
  }
  .title{
    padding-top: toPx(60);
    font-size: toPx(26);
    line-height: 1;
    color: var(--color-black);
  }
  .text{
    padding: toPx(24) 0 0;
    font-size: toPx(26);
    line-height: 1;
    color: var(--color-gray-half);
    .item{
      padding: 0 toPx(17);
    }
  }
  .copyRight{
    font-size: toPx(26);
    padding: toPx(30) 0;
    border-top: 1px solid var(--color-border);
    color: var(--color-gray-half);
  }
  /*ios安全区域*/
  @supports (bottom: constant(safe-area-inset-bottom)) or (bottom: env(safe-area-inset-bottom)) {
    .safe-area {
      width: 100%;
      height: constant(safe-area-inset-bottom);
      height: env(safe-area-inset-bottom);
    }
  }
}
</style>