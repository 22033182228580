<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
// import throttle from '@/utils/throttle'
var mobile = require('is-mobile');

export default {
  data () {
    return {

    }
  },
  methods: {
    _isMobile () {
      return mobile()
      // let flag = navigator.userAgent.match(/(phone|pad|pod|ucweb|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      // alert(navigator.userAgent)
      // return flag;
    },
    doResize () {
      if (window.innerWidth < 600) {
        this.$router.push({ name: 'mobile' })
      } else {
        this.$router.push({ name: 'web' })
      }
    }
  },
  mounted () {
    if (this._isMobile()) {
      this.$router.push({ name: 'mobile' })
    } else {
      this.$router.push({ name: 'web' })
    }
    // window.addEventListener('resize', () => {
    //   throttle(this.doResize,500)
    // })
  },
}
</script>

<style lang="scss">
:root{
  --color-main: #FB6B09;
  --color-gray: rgba(22, 33, 49, 0.9);
  --color-gray-half: rgba(22, 33, 49, 0.5);
   --color-gray-m: rgba(22, 33, 49, 0.1);
  --color-black: #162131;
  --color-card-bg: #FFFFFF;
  --color-border: #E9E9E9;
  --color-white: #FFFFFF;
  --color-grey: rgba(22, 33, 49, 0.2);
}

html,body{
  margin: 0;
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
}

div{
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #F6F8FB;
}

</style>
