<template>
    <div class="app-container keep-px">
      <web-header></web-header>
      <transition name="fade-transform" mode="out-in">
        <router-view  />
      </transition>
      <web-footer></web-footer>
    </div>
</template>

<script>
import WebHeader from '@/components/WebHeader.vue'
  import WebFooter from '@/components/WebFooter.vue';
  export default {
    components: { WebHeader, WebFooter }
  }
</script>

<style lang="scss" scoped>
.keep-px{
  min-width: 1200px;
}
</style>